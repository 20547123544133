<template>
  <div>
    <!-- class : header-wrap : (S) -->
    <header class="header-wrap" id="header" :class="{'st-login':isHeaderActive}">
        <div class="logo-box">
          <h1 class="h1"><router-link to="/main">E-CLUB</router-link></h1>
        </div>
        <!-- class : gnb-wrap : (S) -->
        <div class="gnb-wrap" :class="{open:menuOpen}" @mouseover="enableMouseOver && (menuOpen=true)" @mouseout="menuOpen=false" v-if="$store.state.user.userid">
            <ul>
              <li v-for="menu in menus" :key="menu.id"><a href="javascript:;" :style="clickedMenuStyle(menu.id)" @click="goToTopMenu(menu)">{{menu.title}}</a>
                <ul>
                  <li v-for="child in menu.child" :key="child.id"><a href="javascript:;" @click="goToMenu(menu.id, child)">{{child.title}}</a></li>
                </ul>
              </li>
            </ul>
        </div>
        <!-- class : gnb-wrap : (E)) -->
        <!-- class : util-wrap : (S) -->
        <div class="util-wrap" v-if="$store.state.user.userid">
            <div class="member-box" v-if="$store.state.user.userid == ''">
              <router-link to="/login">로그인</router-link>
              <router-link to="/join">회원가입</router-link>
              <a href="javascript:;" @click="searchOpen=true">회원검색</a>
            </div>
            <div class="member-box" v-else>
              <router-link to="/my/myInfo">마이페이지</router-link>
              <a href="javascript:;" @click="logout">로그아웃</a>
              <a href="javascript:;" @click="searchOpen=true">회원검색</a>
            </div>
            <!--<a href="javascript:;" class="btn-search" @click="searchOpen=true">검색버튼</a>--><!-- script btn-search 있음 -->
            <a href="javascript:;" class="btn-sitemap" @click="popupMenu(true)" >사이트맵</a><!-- script btn-sitemap 있음 -->
        </div>
        <!-- class : util-wrap : (E) -->
        <div class="search-wrap" :class="{open:searchOpen}" >
            <div class="inner-box">
                <div class="tit">찾고싶으신 <span>회원 정보</span>를 입력해주세요.</div>
                <div class="btn-close"><a href="javascript:;" @click="searchOpen=false">닫기버튼</a></div>
                <div class="form-box">
                    <select class="select" v-model="searchColumn">
                      <option value="name">이름</option>
                      <option value="address">주소</option>
                      <option value="mobileNo">휴대폰 번호</option>
                    </select>
                    <input type="text" class="input" placeholder="검색어를 입력해주세요." v-model="searchKeyword" @keydown="onKeydown">
                    <a href="javascript:;" class="btn-search" @click="onClickSearch">검색버튼</a>
                </div>
            </div>
        </div>
    </header>
    <!-- class : header-wrap : (E) -->

    <MenuPopup v-if="popupMenuOpen" @click:bgDimClose="popupMenu"></MenuPopup>
  </div>
</template>

<script>

import MenuPopup from '@/layout/components/Header/menu_popup'
import menuApi from '@/api/MenuApi';
import userApi from "@/api/UserApi";

export default {
  name: 'Headerbar',
  components: {
    MenuPopup
  },
  props:{
    isOpen:{
      type:Boolean,
      default:false
    },
    isLogin:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
      popupMenuOpen:false,
      userId : null,
      menuOpen:false,
      searchOpen:false,

      searchColumn:'name',
      searchKeyword:'',
      enableMouseOver: true,
      menus:[],
      login:false, 
    }
  },
  watch:{
    isOpen(e){
      if(!e){
        this.popupMenuOpen=false
      }
    }   
  },
  created() {
    menuApi.list('HOME').then(resultSet => {
      //console.log(resultSet);
      //console.log('메뉴');
      this.menus = resultSet.map(this.menuParse).filter(m => m !== null).filter(m => !m.protected).filter(m => m.child != null)
    });
  },
  computed: {
    isHeaderActive(){
      let HeaderActive = false;
      if(this.$route.path === "/login"){
        HeaderActive = true
      }
      return HeaderActive;
    }
  },
  
  updated(){
    this.userId = this.$store.state.user.userid
  },
  methods: {
    logout() {
      this.$store.dispatch('user/logout');
      window.location.href='/'
    },
    popupMenu(e){
      this.popupMenuOpen = e
      this.$emit('click:bgDim', this.popupMenuOpen)
    },
    onClickSearch(){
      window.location.href = `/member/0?upper=426&menu=439&searchColumn=`+this.searchColumn+`&searchKeyword=`+this.searchKeyword
    },
    onKeydown(e){
      if(e.keyCode===13){
        this.onClickSearch();
      }
    },
    menuParse(m) {
      if(!m.activated) {
        return null;
      }

      if(m.menuType === 'TYPE_BOARD') {
        m.url = `/board/${m.boardId}`;
      }else if(m.menuType === 'TYPE_PAGE') {
        m.url = `/page/${m.pageId}`
      }

      return {
        menu: m,
        id : m.menuId,
        protected : m.protected,
        title : m.menuName,
        icon : m.menuIcon,
        href : m.url??null,
        child : m?.childMenus?.length??0 > 0 ? m?.childMenus?.map(this.menuParse).filter(c => c !== null) : null
      }
    },
    async goToTopMenu(menu){
      let child = menu.child[0];
      // console.log(menu)
      /*User의 분과 및 동호회 중 제일 처음것으로 적용되어 routing*/
      if(this.userId){
          await userApi.get(this.userId).then((data) =>{
            let detailDepartment = data.departmentGroup? data.departmentGroup[0].groupName:null;
            let detailClubs = data.clubGroup? data.clubGroup[0].groupName:null;
            if(detailClubs){
              for(let i = 0;i<menu.child.length;i++){
                  if(menu.child[i].menu.menuName == detailClubs){
                  child = menu.child[i]
                  break;
                }
              }
            }
            if(detailDepartment){
              for(let i = 0;i<menu.child.length;i++){
                  if(menu.child[i].menu.menuName == detailDepartment){
                  child = menu.child[i]
                  break;
                }
              }
            }
        })
      }
      this.menuOpen=false;
      this.enableSubMenuMouseOver();

      if(menu.href == '/community/0'){
        this.$router.push(menu.href + `?upper=${menu.id}&menu=${child.id}`).catch(() => {});
      }else{
        this.$router.push(child.href + `?upper=${menu.id}&menu=${child.id}`).catch(() => {});
      }
    },
    goToMenu(menuId, child){
      this.menuOpen=false
      this.enableSubMenuMouseOver();
      this.$router.push(child.href + `?upper=${menuId}&menu=${child.id}`).catch(() => {});
    },
    enableSubMenuMouseOver(){

      this.enableMouseOver = false; 
      setTimeout(function (_this){ _this.enableMouseOver = true;}, 300, this);

    },
    clickedMenuStyle(menuId){
      if(this.$route.query.upper==menuId) {
        return {'color': '#526de1'}
      }
    }
  }
}
</script>