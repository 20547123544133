import axios from 'axios';
import {clarify} from "../utils/clear";

class UserApi {
    constructor() {
        this.endpoint = process.env.VUE_APP_BASE_API;
    }

    /* eslint-disable no-unused-vars */
    list(body) {
        let params = new URLSearchParams(clarify(body));
        // for (let [key, val] of addParams.entries()) {
        //     params.append(key, val);
        // }
        return axios.get(`${this.endpoint}/user`, {params}).then(response => response.data);
    }

    get(userId) {
        return axios.get(`${this.endpoint}/user/get/${userId}`, {}).then(response => response.data);
    }

    groupUsers(body) {
        const params = new URLSearchParams(clarify(body));
        return axios.get(`${this.endpoint}/user/group/users`, {params}).then(response => response.data);
    }

    existsId(userId) {
        return axios.get(`${this.endpoint}/user/exists/${userId}`, {}).then(response => response.data);
    }

    existsEmail(email){
        return axios.get(`${this.endpoint}/user/email/exists/${email}`, {}).then(response => response.data);
    }


    existsMobile(moblNo){
        return axios.get(`${this.endpoint}/user/mobile/exists/${moblNo}`, {}).then(response => response.data);
    }


    save(user) {
        return axios.post(`${this.endpoint}/user/save`, user).then(response => response.data);
    }

    delete(userId) {
        return axios.delete(`${this.endpoint}/user/delete?userId=${userId}`, {}).then(response => response.data);
    }

    setPosition(position, userId, groupId) {
        return axios.post(`${this.endpoint}/user/position`, {
            position: position,
            userId: userId,
            groupId: groupId
        }).then(response => response.data);
    }

    updateState(users, state) {
        return axios.put(`${this.endpoint}/user/state/update/${state}`, users);
    }
    updateStateAll(body, state) {
        return axios.put(`${this.endpoint}/user/state/update/all/${state}`, body);
    }
    updateUserGroupState(state, groupId, userId) {
        return axios.put(`${this.endpoint}/user/${userId}/group/${groupId}/state/update/${state}`);
    }

    matchPswd(userId, pswd) {
        return axios.post(`${this.endpoint}/user/password/match/${userId}`, pswd).then(response => response.data);
    }

    updatePswd(userId, param) {
        return axios.post(`${this.endpoint}/user/password/update/${userId}`, param).then(response => response.data);
    }

    uploadImage(f) {
        const formData = new FormData();
        formData.append('file', f);
        return axios.post(`${this.endpoint}/user/upload/image`, formData, {headers: {'Content-Type': 'multipart/form-data'}}).then(response => response.data);
    }

    birthUsers(){
        return axios.get(`${this.endpoint}/user/birthday/users`).then(response => response.data);
    }



    //=========================== 2020-12-28
    sendMobileAuthNo(mobileNo){
        return axios.get(`${this.endpoint}/user/send/authNo/mobile?mobileNo=${mobileNo}`).then(response => response.data);
    }

    sendMailAuthNo(email){
        return axios.get(`${this.endpoint}/user/send/authNo/mail?email=${email}`).then(response => response.data);
    }

    checkMobileAuthNo(mobileNo, authNo){
        return axios.get(`${this.endpoint}/user/check/authNo/mobile/${authNo}?mobileNo=${mobileNo}`).then(response => response.data);
    }

    checkMailAuthNo(email, authNo){
        return axios.get(`${this.endpoint}/user/check/authNo/mail/${authNo}?email=${email}`).then(response => response.data);
    }

    checkUserAuthNo(userId, authNo){
        return axios.get(`${this.endpoint}/user/check/authNo/user/${authNo}?userId=${userId}`).then(response => response.data);
    }

    findUserId(keyType, param){
        return axios.post(`${this.endpoint}/user/find/userId/${keyType}`, param).then(response => response.data);
    }

    checkResetPassword(userId, body){
        const params = new URLSearchParams(clarify(body));
        return axios.get(`${this.endpoint}/user/reset/check/${userId}/password`, {params}).then(response => response.data);
    }

    resetPassword(userId, param){
        return axios.post(`${this.endpoint}/user/reset/${userId}/password`, param).then(response => response.data);
    }

}


export default new UserApi();
