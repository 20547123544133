import axios from 'axios';

class AnalyticsApi {
    constructor() {
        this.endpoint = process.env.VUE_APP_BASE_API;
    }

    getAndIncrease() {
        return axios.get(`${this.endpoint}/analytics/simple`, {}).then(response => response.data);
    }
    get() {
        return axios.get(`${this.endpoint}/analytics/simple/get`, {}).then(response => response.data);
    }

}

export default new AnalyticsApi();
