<template>

  <div class="dg-pop-layer on pop-join-agree-wrap" id="popJoinAgreeTxtBox02">
    <div class="inner-box">
      <div class="tit-box">개인정보 수집·이용 동의서</div>
      <div class="cont-box iscroll2">
        <strong>1. e-CLUB 전자사랑모임에서는 회원가입, 동호회 활동 등을 위하여 아래와 같은 개인정보를 수집하고 있습니다.</strong><br />
        ① 수집항목<br />
        - 필수항목 : 성명, 이메일, 생년월일, 휴대폰, 자택주소, 자택전화번호, 삼성전자 재임시 최종부서/직위, 청탁금지법 대상여부<br />
        - 선택항목 : 회사명, 직책, 직장주소, 직장전화, 직장팩스, 사진<br />
        ② 개인정보 수집방법 : 홈페이지 회원가입, 이메일, 신청서 접수<br />
        <br />
        <strong>2. 개인정보의 수집목적 및 이용목적</strong><br />
        수집한 개인정보는 다음의 목적을 위해 활용합니다.<br />
        ① 회원 관리<br />
        회원제 서비스 이용에 따른 본인확인, 개인 식별, 불량회원의 부정 이용 방지와 비인가 사용 방지, 민원처리, 고지사항 전달<br />
        ② 서비스 제공에 관한 계약 이행<br />
        컨텐츠 제공, 회비 관리, 분과별 및 동호회 운영<br />
        <br />
        <strong>3. 개인정보의 보유기간 및 이용기간</strong><br />
        원칙적으로, 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체없이 파기합니다.&nbsp;<br />
        단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다.<br />
        - 보존 항목 : 고객정보 및 서비스 이용기록, 접속 IP 정보<br />
        - 보존 근거 : 고객님의 탈퇴기록 및 수사기관 등의 협조 등에 이용하기 위하여 보존<br />
        - 보존 기간 : 1년<br />
        그리고 관계법령의 규정에 의하여 보존할 필요가 있는 경우, 아래와 같이 관계법령에서 정한 일정기간 동안 회원정보를<br />
        보관합니다.<br />
        - 회비납부 등 공급에 관한 기록 : 5년 (전자상거래 등에서의 소비자보호에 관한 법률)<br />
        - 회원의 불만 또는 분쟁처리에 관한 기록 : 3년 (전자상거래 등에서의 소비자보호에 관한 법률)<br />
        <br />
        <strong>4. 동의를 거부할 권리 및 동의 거부에 따른 불이익 고지</strong><br />
        회원가입 신청고객은 위 개인정보의 수집 및 이용에 대하여 동의하지 않을 권리를 가지고 있습니다.&nbsp;<br />
        단, 위 개인정보는 &lsquo;e-CLUB 전자사랑모임&rsquo;이 본 사이트를 운영함에 있어 필수적인 정보로써,&nbsp;<br />
        개인정보의 수집 및 이용에 대하여 동의하지 않는 고객은 본 사이트의 회원 가입이 제한될 수 있습니다.&nbsp;<br />
        <br />
        <strong>※ e-CLUB 전자사랑모임에서는 고객의 기본적 인권 침해의 우려가 있는 개인정보 외 고유식별정보 및 민감정보는&nbsp;<br />
          수집하지 않습니다.</strong>
      </div>
      <div class="btn-box"><a href="javascript:;" class="btn-submit dg-pop-close" @click="closePopup">확인</a></div>
    </div>
  </div>

</template>

<script>
export default {
  name: "Agree2Popup",
  data() {
    return {
    }
  },
  methods:{
    closePopup(){
      this.$emit('click:bgDimClose',false)
    }
  }
}
</script>

<style scoped>

</style>