<template>
  <div id="app">
    <router-view :key="reload"/>
  </div>
</template>

<script>
import {intercept} from "@/api/interceptor/auth.interceptor";
import settingApi from "@/api/SettingApi";
import analyticsApi from "@/api/AnalyticsApi";

export default {
  name: 'App',
  computed: {
    reload: {
      get() {
        return this.$store.state.error.reload;
      }
    }
  },
  methods: {
    increaseCounter() {

      // 사용자 일 1회 counting
      if(!this.$cookies.isKey("counter") || !this.$cookies.get("counter")) {

        let dt = new Date(Date.now() + 24 * 60 * 60 * 1000);
        // let dt = new Date(Date.now() + 10 * 1000);
        dt.setHours(0, 0, 0, 0)
        this.$cookies.set("counter", true, dt)

        analyticsApi.getAndIncrease().then(result => {
          // console.log(result);
          this.$store.commit('setCounter', result);
        })
      } else {
        // console.log(this.$cookies.keys().join("\n"))
        // console.log(this.$cookies.get("counter"))
        // this.$cookies.remove("counter")
        // this.$cookies.keys().forEach((cookie) => console.log(cookie));

        analyticsApi.get().then(result => {
          // console.log(result);
          this.$store.commit('setCounter', result);
        })
      }

    }
  },
  created() {
    intercept(this.$store);

    settingApi.get().then(setting => {
      // console.log(setting)
      this.$store.commit('setConfig', setting);
      document.title = setting.site.name;
      // this.setting = setting;
    });

    this.increaseCounter();
    // analyticsApi.simple().then(result => {
    //   this.$store.commit('setCounter', result);
    // })

  }
};
</script>

<style lang="scss">
  @import "./assets/front/css/import.css";
  @import "./assets/style/vuetify";

</style>
