<template>
  <div class="footer-wrap " :class="{'st-login':isFooterActive}">
    <div class="pc-only">
      <div class="link-box">
        <div class="inner-base-box">
          <div class="left-box">
            <router-link to="/page/3250?upper=425&menu=433" class="st-bold">전자사랑 소개</router-link>
            <a href="javascript:;" class="st-bold" @click="popupAgree3(true)">회원 약관</a>
            <a href="javascript:;" class="st-bold" @click="popupAgree2(true)">개인정보처리방침</a>
          </div>
          <div class="right-box"><router-link to="/page/3254?upper=425&menu=438" class="st-bold">찾아오시는길</router-link></div>
        </div>
      </div>
      <div class="info-box">
        <div class="inner-base-box">
          <div class="address">{{setting.site.address}} {{setting.site.addressDetail}}</div>
          <div class="tel">
            <span>TEL : {{ setting.site.tel }}  </span>
            <span>FAX : {{ setting.site.fax }}  </span>
            <span>E-mail : {{ setting.site.email }}</span>
          </div>
          <div class="visit-count-box"><p><span>TODAY</span><em> {{counter.today.total}} </em></p> <p><span>TOTAL</span><em>{{ counter.total.total }}</em></p></div>
        </div>
      </div>
      <div class="copyright"><div class="inner-base-box">Copyright ⓒ <strong>{{ setting.site.name }}</strong>. All Rights Reserved.</div></div>
    </div>

    <Agree2Popup v-if="agree2" @click:bgDimClose="popupAgree2"></Agree2Popup>
    <Agree3Popup v-if="agree3" @click:bgDimClose="popupAgree3"></Agree3Popup>

    <MenuPopup v-if="popupMenuOpen" @click:bgDimClose="popupMenu"></MenuPopup>

    <div class="mb-footer-wrap mb-only">
      <div class="main-address-box">
        <div class="address">{{setting.site.address}} {{setting.site.addressDetail}}</div>
          <div class="tel">
            <span>TEL : {{ setting.site.tel }}  </span>
            <span>FAX : {{ setting.site.fax }}  </span>
            <span>E-mail : {{ setting.site.email }}</span>
          </div>
      </div>
      <div class="footer-menu-box">
        <ul>
          <li class="smenu"><a href="/"><i class="icon-home"></i><span>홈</span></a></li>
          <li class="smenu"><a href="javascript:;" @click="popupMenu(true)"><i class="icon-menu"></i><span>전체메뉴</span></a></li>
          <li class="smenu"><a href="/board/566?upper=431&menu=487"><i class="icon-notice"></i><span>새소식</span></a></li>
          <li class="smenu"><a href="/my/myInfo"><i class="icon-mypage"></i><span>마이페이지</span></a></li>
        </ul>
      </div>
    </div>
  </div>
  
</template>

<script>
import Agree2Popup from '@/views/login/popup/agree2_popup'
import Agree3Popup from '@/views/login/popup/agree3_popup'
import MenuPopup from '@/layout/components/Header/menu_popup'
import menuApi from '@/api/MenuApi';

export default {
  name: "Footer",
  components:{
    Agree2Popup,
    Agree3Popup,
    MenuPopup,
  },
  props:{
    isOpen:{
      type:Boolean,
      default:false
    }
  }, 
  data() {
    return {
      popupMenuOpen:false,
      agree2:false,
      agree3:false,
    }
  },
  computed: {
    setting() {
      return this.$store.state?.config??{site:{}};
    },
    counter() {
      return this.$store.state?.counter??{today:{}, total:{}};
    },
    isFooterActive(){
      let footerActive = false;
      if(this.$route.path === "/login" || this.$route.path === "/find/findId" || this.$route.path === "/find/findPwd" || this.$route.path === "/join"){
        footerActive = true
      }else{
        footerActive = false
      } 
      return footerActive;
    }
  }, 
  mounted(){
  },
  watch:{
    isOpen(e){
      if(!e){
        this.agree2=false
        this.agree3=false
        this.popupMenuOpen=false
      }
    } 
  },
  created() {
    // this.setting = this.$store.state.config;
    // settingApi.get().then(setting => {
    //   console.log(setting)
    //   this.setting = setting;
    // });
    menuApi.list('HOME').then(resultSet => {
      //console.log(resultSet);
      //console.log('메뉴');
      this.menus = resultSet.map(this.menuParse).filter(m => m !== null).filter(m => !m.protected).filter(m => m.child != null)
    });
  },
  methods:{
    popupMenu(e){
      this.popupMenuOpen = e
      this.$emit('click:bgDim', this.popupMenuOpen)
    },
    popupAgree2(e){
      this.agree2 = e
      this.$emit('click:bgDim', this.agree2)
    },
    popupAgree3(e){
      this.agree3 = e
      this.$emit('click:bgDim', this.agree3)
    }, 
  }

}
</script>

<style scoped>

</style>