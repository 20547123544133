import axios from 'axios';

class SettingsApi {
    constructor() {
        this.endpoint =  process.env.VUE_APP_BASE_API;
    }

    get() {
        return axios.get(`${this.endpoint}/setting`).then(response=>response.data);
    }

}

export default new SettingsApi();
