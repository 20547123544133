<template>
  <div id="wrap">
    <DimLayer :isOpen="isOpen" @click:bgDim="onClickBgDim"/>
    <Header :isOpen="isOpen" @click:bgDim="onClickBgDim" :isLogin="isLogin"/>
    <router-view :key="$route.fullPath" :isOpen="isOpen" @click:bgDim="onClickBgDim" @click:login="onClickLogin"/>
    <div class="btn-top" @click="$vuetify.goTo('#header')"/>
    <Footer :isOpen="isOpen" @click:bgDim="onClickBgDim"/>
  </div>
</template>

<script>
import DimLayer  from '@/layout/components/DimLayer'
import Header  from '@/layout/components/Header'
import Footer from "@/layout/components/Footer/index";

export default {
  name: 'Layout',
  components: {
    Footer,
    DimLayer,
    Header,
  },
  props: {
  },
  data() {
    return {
      isOpen:false,
      isLogin:false
    }
  },
  created(){
  },
  methods: {
    onClickBgDim(e){
      if(e){
        // eslint-disable-next-line no-undef
        $('body').css({
          overflow:"hidden"
        });
      }else{
        // eslint-disable-next-line no-undef
        $('body').css({
          overflow:"auto"
        });
      }
      this.isOpen = e
    },
    onClickLogin(e){
      this.isLogin=e
      // console.log('onClickLogin'+e)
    }
  }
}
</script>