import {getToken, setToken, removeToken, setRefresh} from '../../utils/auth'
import jwt_decode from 'jwt-decode';
import authApi from '@/api/auth'

const state = () => {
    const token = getToken();
    try {
        const data = jwt_decode(token);

        return {
            token,
            username: data['name'],
            userid: data['user_id'],
            roles: data.authorities,
            menus: data.menus
        }
    } catch (e) {
        return {
            token: '',
            username: '',
            userid: '',
            roles: [],
            menus: []
        }
    }
}

const actions = {
    login: ({commit}, payload) => {
        commit('CLEAR');
        return authApi.login(payload.username, payload.password).then((data) => {
            const access = jwt_decode(data.access_token);
            // console.log("data");
            // console.log(data);
            // console.log("access");
            // console.log(access);
            commit('SET_TOKEN', data.access_token);
            commit('SET_USERNAME', data.name);
            commit('SET_REFRESH', data.refresh_token);
            commit('SET_USERID', data.user_id);
            commit('SET_ROLES', access.authorities);
            commit('SET_MENUS', data.menus);
            return true;

            // if (access.authorities && access.authorities.indexOf('관리자')>=0) {
            // if (access.adminMenus && access.adminMenus.length > 0) {
            //     commit('SET_TOKEN', data.access_token);
            //     commit('SET_USERNAME', access.user_name);
            //     commit('SET_REFRESH', data.refresh_token);
            //     commit('SET_USERID', data.user_id);
            //     commit('SET_ROLES', access.authorities);
            //     return true;
            // } else {
            //     throw new Error('접근 가능한 관리 메뉴가 없습니다.');
            // }

        });
    },
    logout: ({commit})=> {
        commit('CLEAR');
    }
}
const getters = {
    token: state => state.user?.token,
    username: state => state.user?.username || '',
    roles: state => state.user?.roles
}

const mutations = {
    SET_TOKEN: (state, token) => {
        setToken(token);
    },
    SET_REFRESH: (state, refresh) => {
        setRefresh(refresh);
    },
    SET_USERNAME: (state, username) => {
        state.username = username;
    },
    SET_USERID: (state, userId) => {
        state.userid = userId;
    },
    SET_ROLES: (state, roles) => {
        state.roles = roles;
    },
    SET_MENUS: (state, menus) => {
        state.menus = menus;
    },
    CLEAR: (state) => {
        state.token = '';
        state.roles = [];
        state.username = '';
        state.userid = '';
        removeToken();
    }

}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
