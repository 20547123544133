import axios from 'axios';
import {clarify} from "@/utils/clear";

class PresentApi {
    constructor() {
        this.endpoint = process.env.VUE_APP_BASE_API;
    }

    //선물회차정보
    get(authNo, mobileNo, presentId) {
        return axios.get(`${this.endpoint}/presents/${presentId}?authNo=${authNo}&mobileNo=${mobileNo}`, {}).then(response=>response.data);
    }
    //주문정보
    getOrder(presentId, userId, authNo, mobileNo) {
        return axios.get(`${this.endpoint}/presents/${presentId}/${userId}/order?authNo=${authNo}&mobileNo=${mobileNo}`, {}).then(response=>response.data);
    }
    //제품목록
    productList(authNo, mobileNo, presentId) {
        return axios.get(`${this.endpoint}/presents/${presentId}/products?authNo=${authNo}&mobileNo=${mobileNo}`, {}).then(response=>response.data);
    }

    //선물신청
    save(present) {
        return axios.post(`${this.endpoint}/presents/${present.presentId}/request?authNo=${present.authNo}&mobileNo=${present.authMobileNo}`, present).then(response=>response.data);
    }
    //사용자주문목록
    orderList(userId, body){
        const params = new URLSearchParams(clarify(body));
        return axios.get(`${this.endpoint}/presents/${userId}/orders`, {params}).then(response=>response.data);
    }
    //제품상세정보
    getProduct(productId, authNo, mobileNo) {
        return axios.get(`${this.endpoint}/presents/product/${productId}?authNo=${authNo}&mobileNo=${mobileNo}`, {}).then(response=>response.data);
    }


    //인증번호발송
    sendMobileAuthNo(body){
        const params = new URLSearchParams(clarify(body));
        return axios.get(`${this.endpoint}/presents/send/authNo`, {params}).then(response=>response.data);
    }
    //인증번호체크
    checkMobileAuthNo(authNo, mobileNo){
        return axios.get(`${this.endpoint}/presents/check/authNo?authNo=${authNo}&mobileNo=${mobileNo}`).then(response=>response.data);
    }


    // preview 제품목록
    previewProductList(presentId) {
        return axios.get(`${this.endpoint}/presents/preview/${presentId}/products`, {}).then(response=>response.data);
    }
    // preview 제품상세정보
    previewProduct(productId) {
        return axios.get(`${this.endpoint}/presents/preview/product/${productId}`, {}).then(response=>response.data);
    }
}


export default new PresentApi();
