import axios from 'axios';
import {getToken, refreshToken} from '@/utils/auth';
import jwt_decode from 'jwt-decode';
import {throttle} from 'lodash'
export function intercept($store) {
    // const refresh = throttle((config)=>{
    //     const access_token = getToken();
    //     if (access_token && jwt_decode(access_token).exp > Date.now()/1000) {
    //         return axios.request(config);
    //     } else {
    //         return refreshToken().then(()=> {
    //             return axios.request(config);
    //         })
    //     }
    // }, 1000);
    const reload = throttle(()=>{
        $store.dispatch('error/reload');
    }, 1000);
    const refresh = (config)=> {
        const access_token = getToken();
        if (access_token && jwt_decode(access_token).exp > Date.now() / 1000) {
            return axios.request(config);
        } else {
            return refreshToken().then(() => {
                return axios.request(config);
            }).catch(error => {
                if (error.response && error.response.status === 401 && error.response.data.error_description.startsWith('Invalid refresh token (expired)')) {
                    $store.dispatch('user/logout');
                    reload();
                    return Promise.reject(error);
                }
            })
        }
    };
    axios.interceptors.request.use(config => {
        const token =  getToken();
        // console.log(jwt_decode(token));
        if (token && config.url.indexOf(process.env.VUE_APP_HYPER_MEETING_API) === -1) {
            config.headers['Authorization'] = `bearer ${token}`;
        }
        return config;
    }, error => {
        return Promise.reject(error);
    });

    axios.interceptors.response.use(response => {
        return response;
    }, error => {

        // console.log(error)
        // console.log(error.response)
        if (error.response && error.response.status === 401 && error.response.data.error_description.startsWith('Access token expired')) {
            return refresh(error.config);
        }
        if (error.message ==='Network Error') {
            $store.dispatch('error/alert', '서버에 문제가 발생했습니다. 관리자에게 문의 바랍니다.');
        } else if (error.response && error.response.status === 401) {
            $store.dispatch('error/alert', error.response.data.error_description);
        } else if (error.response && error.response.status  === 400) {
            $store.dispatch('error/alert', error.response.data.message || error.response.data.error_description);
        }

        return Promise.reject(error);
    })
}
