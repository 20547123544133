<template>
  <div class="header-wrap ">
  <div class="sitemap-wrap dg-pop-layer on" id="popSiteMap">
    <div class="tit-box">
      <div class="tit"><span class="pc-only">전자사랑 사이트맵</span> <span class="mb-only">전체메뉴</span></div>
      <div class="btn-close"><a href="javascript:;" class="dg-pop-close" @click="closePopup">닫기버튼</a></div>
    </div>
    <div class="s-gnb-wrap">
      <div class="mb-only gnb-top-box">
        <div class="inner-box">
          <a href="/group/schedule?upper=431&menu=488"><i class="icon-calender"></i><span>행사게시판</span></a>
          <a href="/board/568?upper=431&menu=490"><i class="icon-photo"></i><span>포토게시판</span></a>
          <a href="/board/569?upper=431&menu=491"><i class="icon-info"></i><span>정보/소통</span></a>
          <a href="/board/566?upper=431&menu=487"><i class="icon-notice"></i><span>공지사항</span></a>
          <a href="/board/567?upper=431&menu=489"><i class="icon-joy"></i><span>경조사</span></a>
          <a href="/member/0?upper=426&menu=439"><i class="icon-mem-list"></i><span>회원목록</span></a>
        </div>
      </div>
      <!-- script 로 가져옴 s-gnb-wrap -->

      <!-- class : gnb-wrap : (S) -->
        <ul>
          <li v-for="menu in menus" :key="menu.id" :class="{'st-2':menu.child.length>8}"><a href="javascript:;" @click="goToTopMenu(menu)" >{{ menu.title }}</a>
            <ul>
              <li v-for="child in menu.child" :key="child.id" ><a href="javascript:;" @click="goToMenu(menu.id, child)">{{child.title}}</a></li>
            </ul>
          </li>
        </ul>
      </div>
      <!-- class : gnb-wrap : (E)) -->


  </div>
  </div>


</template>

<script>
import menuApi from "@/api/MenuApi";

export default {
  name: "MenuPopup",
  data(){
    return {
      menus: []
    }
  },
  created() {
    menuApi.list('HOME').then(resultSet => {
      this.menus = resultSet.map(this.menuParse).filter(m => m !== null).filter(m => !m.protected).filter(m => m.child != null)
    });
  },
  methods: {
    closePopup() {
      this.$emit('click:bgDimClose', false)
    },
    menuParse(m) {
      if(!m.activated) {
        return null;
      }

      if(m.menuType === 'TYPE_BOARD') {
        m.url = `/board/${m.boardId}`;
      }else if(m.menuType === 'TYPE_PAGE') {
        m.url = `/page/${m.pageId}`
      }
      
      return {
        menu: m,
        id : m.menuId,
        protected : m.protected,
        title : m.menuName,
        icon : m.menuIcon,
        href : m.url??null,
        child : m?.childMenus?.length??0 > 0 ? m?.childMenus?.map(this.menuParse).filter(c => c !== null) : null
      }
    }
    ,
    goToTopMenu(menu){
      let child = menu.child[0];
      this.menuOpen=false;

      if(menu.href == '/community/0'){
        this.$router.push(menu.href + `?upper=${menu.id}&menu=${child.id}`).catch(() => {});
      }else{
        this.$router.push(child.href + `?upper=${menu.id}&menu=${child.id}`).catch(() => {});
      }
      this.closePopup()
    },
    goToMenu(menuId, child){
      this.menuOpen=false
      this.$router.push(child.href + `?upper=${menuId}&menu=${child.id}`).catch(() => {});
      this.closePopup()
    }
  }
}
</script>

<style scoped>

</style>