import axios from 'axios';

class MenuApi {
    constructor() {
        this.endpoint =  process.env.VUE_APP_BASE_API;
    }

    list(menuCategory) {
        // return axios.get(`${this.endpoint}/menu/list/${parentId??''}`, {}).then(response=>response.data);
        return axios.get(`${this.endpoint}/menu/list/${menuCategory}`, {}).then(response=>response.data);
    }

    getMenu(menuId) {
        return axios.get(`${this.endpoint}/menu/get/${menuId}`, {}).then(response=>response.data);
    }

    save(menu) {
        return axios.post(`${this.endpoint}/menu/save`, menu).then(response=>response.data);
    }

    deleteMenu(menuId) {
        return axios.delete(`${this.endpoint}/menu/delete/${menuId}`, {}).then(response=>response.data);
    }

    moveMenu(move, destination, position) {
        let param = {
            move: move,
            destination: destination,
            position: position
        }
        axios.put(`${this.endpoint}/menu/move`, param).then(response=>response.data);
    }

}

export default new MenuApi();
