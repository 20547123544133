import Vue from 'vue'
import Router from 'vue-router'
import Layout from '../layout'
import Gift from '../layout/gift'
import {getToken} from "../utils/auth";
import store from "../store/index";

Vue.use(Router)

const routes = [
  /******* 메인 *******/
  {
    path: '/',
    component: Layout,
    redirect: '/main',
    props: {
      isHeader: false,
    },
    children:[
      {
        name: 'main',
        path: `main`,
        component: () => import('@/views/main/main'),
      },
    ]
  },
  /******* 로그인 *******/
  {
    path: `/login`,
    component: Layout,
    children:[
      {
        name: 'login',
        path: `/login`,
        component: () => import('@/views/login/login'),
      },
      {
        name: 'loginGuide',
        path: `/loginGuide`,
        component: () => import('@/views/login/login_guide'),
      }
    ]
  },
  /******* 가입 *******/
  {
    path: `/join`,
    component: Layout,
    children:[
      {
        name: 'join',
        path: `/join`,
        component: () => import('@/views/login/join'),
      },
      {
        name: 'joinResult',
        path: '/join/joinResult',
        params: {},
        component: () => import('@/views/login/join_result'),
      },
    ]
  },
  /******* 찾기 *******/
  {
    path: '/find',
    component: Layout,
    children:[
      {
        name: 'findId',
        path: `findId`,
        component: () => import('@/views/login/find/findId'),
      },
      {
        name: 'findIdResult',
        path: `findIdResult`,
        params: {},
        component: () => import('@/views/login/find/findId_result'),
      },
      {
        name: 'findPwdId',
        path: 'findPwdId',
        component: () => import('@/views/login/find/findPwd_id')
      },
      {
        name: 'findPwd',
        path: 'findPwd',
        params: {},
        component: () => import('@/views/login/find/findPwd')
      },
      {
        name: 'findPwdReset',
        path: 'findPwdReset',
        params: {},
        component: () => import('@/views/login/find/findPwd_reset')
      },
      {
        name: 'findPwdResult',
        path: 'findPwdResult',
        component: () => import('@/views/login/find/findPwd_result')
      }
    ]
  },
  /******* 마이페이지 *******/
  {
    path: `/my`,
    component: Layout,
    children:[
      {
        name: 'myInfo',
        path: `myInfo`,
        component: () => import('@/views/mypage/member_info'),
      },
      {
        name: 'inquiry',
        path: `inquiry`,
        component: () => import('@/views/mypage/inquiry/inquiry_index'),
      },
      {
        name: 'meetList',
        path: `meetList`,
        component: () => import('@/views/mypage/meet_list'),
      },
      {
        name: 'message',
        path: `message`,
        component: () => import('@/views/mypage/message/message_index'),
      },
      {
        name: 'board',
        path: `board`,
        component: () => import('@/views/mypage/board_list'),
      },
      {
        name: 'videoConference',
        path: `videoConference`,
        component: () => import('@/views/mypage/video/video_list'),
      },
      {
        name: 'myConference',
        path: `myConference`,
        component: () => import('@/views/mypage/video/video_my'),
      },
      {
        name: 'videoInvite',
        path: `videoInvite/:roomId`,
        component: () => import('@/views/mypage/video/video_invite'),
      }
    ]
  },
  /*******     메   뉴     *******/

  /******* 전자사랑모임 *******/
  {
    path: `/meeting`,
    component: Layout,
    children:[
      {
        name: 'meetingIndex',
        path: `/meeting/:tapNo`,
        component: () => import('@/views/board/meeting/meeting_index'),
      },
      {
        name: 'meetingIndex',
        path: `/page/:id`,
        component: () => import('@/views/board/meeting/meeting_index'),
      }
    ]
  },

  /******* 회원안내 *******/
  {
    path: `/member`,
    component: Layout,
    children:[
      {
        name: 'member',
        path: `/member/:tapNo`,
        component: () => import('@/views/board/member/member_index'),
      },
    ]
  },
  /******* 분과게시판 *******/  /******* 동호회 게시판 *******/
  {
    path: `/subdivision`,
    component: Layout,
    children: [
      {
        name: 'subdivision',
        path: `/board/write/:id`,
        component: () => import('@/views/board/board_write'),
      },
      {
        name: 'subdivision',
        path: `/board/:id`,
        component: () => import('@/views/board/board_index'),
      },
      {
        name: 'subdivisionDetail',
        path: `/board/:id/:articleId`,
        component: () => import('@/views/board/board_index'),
      },
    ]
  },
    /******* 커뮤니티 *******/
    {
      path: `/community`,
      component: Layout,
      children:[
        {
          name: 'communityIndex',
          path: `/community/0`,
          component: () => import('@/views/board/community/community_main'),
        },
        // {
        //   path: `/community/4`,
        //   component: () => import('@/views/board/community/information'),
        // },
        // {
        //   path: `/community/5`,
        //   component: () => import('@/views/board/community/communication'),
        // },
      ]
    },
    {
      path: `/group`,
      component: Layout,
      children: [
        {
          name: 'schedule',
          path: 'schedule',
          component: () => import('@/views/meeting/schedule'),
          props: true
        },
        {
          name: 'schedule/registration/:meetingId?',
          path: 'schedule/registration/:meetingId?',
          component: () => import('@/views/meeting/meetingForm'),
          props:true
        },
      ]
    },
    




  {
    path: `/login`,
    component: Layout,
    children:[
      {
        name: 'login',
        path: `/login`,
        component: () => import('@/views/login/login'),
      },
      {
        name: 'loginGuide',
        path: `/loginGuide`,
        component: () => import('@/views/login/login_guide'),
      }
    ]
  },

    {
      path: '/gift',
      component: Gift,
      children:[
        {
          name: 'gift',
          path: `/gift/:id`,
          component: () => import('@/views/gift/auth'),
        },
        {
          name: 'register',
          path: `/gift/:id/register`,
          component: () => import('@/views/gift/register'),
          props:true
        },
        {
          name: 'preview',
          path: `/gift/:id/preview`,
          component: () => import('@/views/gift/preview'),
          props:true
        },
        {
          name: 'detail',
          path: `/gift/:id/detail`,
          component: () => import('@/views/gift/detail'),
        },
        {
          name: 'history',
          path: `/gift/:id/history`,
          component: () => import('@/views/gift/history'),
        },
      ]
    }

  ]


const router = new Router({
  scrollBehavior() {
    return { x:0, y: 0}
  },
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next)=> {
  if (getToken()) {
    let menus = store.state.user.menus;
    let menuId = to.query.menu;

    if (to.path==='/main' || to.path.indexOf('/my') === 0) {
      next();
    }else if(menus === null || menus.find((e)=>e==menuId) == undefined){
      alert('접근 권한이 없습니다.');
      return;
    } else {
      next();
    }
  } else if (to.path==='/join') {
    next();
  } else if (to.path==='/join/joinResult') {
    next();
  } else if (to.path==='/find/findId') {
    next();
  } else if (to.path==='/find/findIdResult') {
    next();
  } else if (to.path==='/find/findPwdId') {
    next();
  } else if (to.path==='/find/findPwd') {
    next();
  } else if (to.path==='/find/findPwdReset') {
    next();
  } else if (to.path==='/find/findPwdResult') {
    next();
  } else if (to.path==='/loginGuide') {
    next();
  } else if (to.path==='/login') {
    next();
  } else if (to.path.indexOf('/gift') === 0) {
    next();
  } else if (to.path == '/robots.txt'){
      next();
  } else {
    next('/login');
  }
})

export default router
