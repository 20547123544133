<template>
  <div>
    <DimLayer :isOpen="isOpen" @click:bgDim="onClickBgDim"/>
    <router-view :key="$route.fullPath" :isOpen="isOpen" @click:bgDim="onClickBgDim" @click:login="onClickLogin"/>
  </div>
</template>


<style>
h2.h2 { margin-top: 14.077px;}
</style>

<script>
import DimLayer  from '@/layout/components/DimLayer'
import PresentApi from "@/api/PresentApi";

export default {
  name: 'Gift',
  components: {
    DimLayer
  },
  mounted(){
    let style = document.createElement('link');
    style.type = "text/css";
    style.rel = "stylesheet";
    style.href = '/mobile/css/mobile.css';
    document.head.appendChild(style);

    style.href = '/mobile/css/mobile_sub.css';
    document.head.appendChild(style);
  },
  props: {
  },
  data() {
    return {
      isOpen:false,
      isLogin:false
    }
  },
  created(){
    if(this.$route.name !== 'preview') {
      PresentApi.get(this.userInfo.authNo, this.userInfo.moblNo, this.presentId).catch(() => {
        alert("선물 회차 정보가 없습니다.");
        return history.back();
      })
    }

  },
  methods: {
    onClickBgDim(e){
      if(e){
        // eslint-disable-next-line no-undef
        $('body').css({
          overflow:"hidden"
        });
      }else{
        // eslint-disable-next-line no-undef
        $('body').css({
          overflow:"auto"
        });
      }
      this.isOpen = e
    },
    onClickLogin(e){
      this.isLogin=e
      // console.log('onClickLogin'+e)
    }
  }
}
</script>