<template>

  <div class="dg-pop-layer on pop-join-agree-wrap" id="popJoinAgreeTxtBox03">
    <div class="inner-box">
      <div class="tit-box">전자사랑모임 이용 약관</div>
      <div class="cont-box iscroll2">
        <div style="text-align: center;">제 1 장 총 칙</div><br />
        <br />
        제 1 조 (목적)<br />
        <br />
        이 약관은 전자사랑모임(이하 &quot;e-CLUB&quot;라고 합니다)이 제공하는 모든 서비스의 이용조건 및 절차에 관한 사항과 기타 필요한 사항을 규정함을 목적으로 합니다.<br />
        &nbsp;<br />
        제 2 조 (약관의 효력)<br />
        <br />
        1. 이 약관은 서비스를 통하여 이를 공지하거나 전자우편 등 기타의 방법으로 회원에게 통지함으로써 효력이 발생합니다.<br />
        <br />
        2. e-CLUB은 필요시 이 약관의 내용을 변경할 수 있으며, 변경된 약관은 제1항과 같은 방법으로 공지 또는 통지한 때로부터 그 효력을 발생합니다.<br />
        &nbsp;<br />
        제 3 조 (약관 이외의 준칙)<br />
        &nbsp;<br />
        이 약관에 명시되지 않은 사항은 관련법령에 규정되어 있는 경우 그 규정에 따릅니다.<br />
        &nbsp;<br />
        <br />
        <div style="text-align: center;">제 2 장 서비스 이용계약</div><br />
        <br />
        제 5 조 (이용계약의 성립)<br />
        <br />
        이용계약은 서비스이용신청자의 이용신청에 대하여 e-CLUB이 승인을 함으로써 성립합니다.<br />
        <br />
        제 6 조 (이용신청)<br />
        <br />
        1. 서비스 이용신청자는 e-CLUB이 요청하는 회원에 관한 정보를 본인이 성실하게 작성, 등록함을 원칙으로 합니다.<br />
        <br />
        2. 회원 등록은 이용희망자가 본 이용약관을 열람한 후 회원가입신청서 양식에 따라 관련 사항을 기재하고 &quot;동의함&quot; 버튼을 체크하면 이 약관에 합법적으로 동의하는 것으로 간주합니다.<br />
        <br />
        제 7 조 (이용신청 승낙 유보)<br />
        <br />
        e-CLUB은 아래 항목에 해당되는 경우 이용신청에 대한 승낙을 제한할 수 있고, 그 사유가 해소될 때까지 승낙을 유보할 수 있습니다.<br />
        <br />
        이 경우 e-CLUB은 이를 이용 신청자에 메일 등으로 알립니다. 다만, 클럽의 귀책사유 없이 이용신청자에게 통지할 수 없는 경우는 예외로 합니다.<br />
        <br />
        ① 서비스 관련 설비의 용량이 부족한 경우<br />
        ② 기술상 장애사유가 있는 경우<br />
        ③ 기타 클럽이 필요하다고 인정되는 경우<br />
        <br />
        제 8 조 (이용신청의 거부)<br />
        <br />
        e-CLUB는 아래 항목에 해당하는 사항을 인지하는 경우 동 이용신청을 승낙하지 아니합니다.<br />
        <br />
        ① 이름이 실명이 아닌 경우<br />
        ② 다른 사람의 명의를 사용하여 신청한 경우<br />
        ③ 가입신청서의 내용을 허위로 기재한 경우<br />
        ④ 사회의 안녕질서 또는 미풍양속을 저해할 목적으로 신청한 경우<br />
        <br />
        제 9 조 (계약 해지)<br />
        <br />
        1. 회원이 서비스 이용 계약을 해지하고자 하는 때에는 회원 본인이 사전에 온라인(E-mail), 방문, 전화 또는 팩스를 통하여 e-CLUB에 해지 신청을 해야 합니다.<br />
        <br />
        2. 가입해지 여부는 기존의 ID, 비밀번호로 로그인이 되지 않으면 해지된 것이며,한번 해지된 ID는 기존 사용자라도 사용할 수 없음을 알려드립니다.<br />
        &nbsp;<br />
        3. 회사는 회원이 다음 사항에 해당하는 행위를 하였을 경우, 사전 통지 없이 이용 계약을 해지하거나 또는 기간을 정하여 서비스 이용을 중지할 수 있습니다&nbsp;<br />
        <br />
        가. 공공 질서 및 미풍 양속에 반하는 경우&nbsp;<br />
        나. 범죄적 행위에 관련되는 경우&nbsp;<br />
        다. 국익 또는 사회적 공익을 저해할 목적으로 서비스 이용을 계획 또는 실행할 경우&nbsp;<br />
        라. 타인의 ID 및 비밀번호를 도용한 경우&nbsp;<br />
        마. 타인의 명예를 손상시키거나 불이익을 주는 경우&nbsp;<br />
        바. 같은 사용자가 다른 ID로 이중 등록을 한 경우&nbsp;<br />
        사. 서비스에 위해를 가하는 등 건전한 이용을 저해하는 경우&nbsp;<br />
        아. 기타 관련 법령이나 회사가 정한 이용조건에 위배되는 경우&nbsp;<br />
        &nbsp;<br />
        <br />
        <div style="text-align: center;">제 3 장 서비스 제공 및 이용</div><br />
        <br />
        제 10 조 (서비스 제공 범위 변경)<br />
        <br />
        e-CLUB이 필요한 경우에는 서비스의 내용을 추가 또는 변경할 수 있습니다. 이때 e-CLUB은 그 내용을 사전에 공지하는 것을 원칙으로 합니다.<br />
        <br />
        제 11 조 (서비스 이용)<br />
        <br />
        1. 서비스 이용은 e-CLUB의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴를 원칙으로 합니다.<br />
        2. 단, 시스템 점검, 백업 등 e-CLUB이 필요한 경우 또는 시스템 장애, 서비스 이용의 폭주 등 불가항력으로 인하여 서비스를 중단할 수 있습니다.<br />
        <br />
        제 12 조 (정보의 제공 및 광고의 게재)<br />
        <br />
        1. e-CLUB은 서비스를 운용함에 있어서 각종 정보를 서비스에 게재하는 방법 등으로 회원에게 제공할 수 있습니다. e-CLUB이 필요한 경우에는 서비스의 내용을 추가 또는 변경할 수 있습니다. 이때 e-CLUB은 그 내용을 사전에 공지하는 것을 원칙으로 합니다.<br />
        2. e-CLUB은 서비스 운용과 관련하여 서비스 화면, 전자 우편 등에 광고 등을 게재할 수 있습니다.&nbsp;<br />
        &nbsp;<br />
        <br />
        <div style="text-align: center;">제 4 장 권리&middot;및 의무</div><br />
        <br />
        제 13 조 (회사의 의무)<br />
        <br />
        1. e-CLUB은 특별한 사정이 없는 한 회원이 신청한 서비스 제공 개시일에 서비스를 이용할 수 있도록 합니다.&nbsp;<br />
        2. e-CLUB은 이 약관에서 정한 바에 따라 계속적, 안정적으로 서비스를 제공할 의무가 있습니다.&nbsp;<br />
        3. e-CLUB은 회원으로부터 소정의 절차에 의해 제기되는 의견에 대해서는 적절한 절차를 거처 처리하며, 처리시 일정기간이 소요될 경우 회원에게 그 사유와 처리 일정을 알려주어야 합니다.&nbsp;<br />
        4. e-CLUB은 회원의 정보를 철저히 보안 유지하며, 양질의 서비스를 운영하거나 개선하는 데에만 사용하고, 이외의 다른 목적으로 타 기관 및 개인에게 양도 하지 않습니다.<br />
        <br />
        제 14 조 (사생활 보호)<br />
        <br />
        1. e-CLUB은 서비스와 관련하여 기득한 회원의 개인정보를 본인의 사전 승낙 없이 타인에게 누설, 공개 또는 배포할 수 없으며, 서비스 관련 업무 이외의 상업적 목적으로 사용할 수 없습니다.<br />
        <br />
        다만, 아래 항목에 해당되는 경우에는 그러하지 아니합니다.<br />
        ① 관계법령에 의하여 수사상의 목적으로 관계기관으로부터 요구 받은 경우<br />
        ② 정보통신윤리위원회의 요청이 있는 경우<br />
        ③ 기타 관계법령에 의한 경우<br />
        <br />
        제 15 조 (회원의 의무)<br />
        <br />
        1. ID와 비밀번호에 관한 모든 관리의 책임은 회원에게 있으며, 이 약관 및 관계법령에서 규정한 사항을 준수하여야 합니다.<br />
        2. 회원은 서비스의 일부로 보내지는 e-CLUB의 전자우편을 통한 광고 정보를 받는 것에 동의합니다.&nbsp;<br />
        3. 자신의 ID가 부정하게 사용된 경우, 회원은 반드시 e-CLUB에 그 사실을 통보해야 합니다.&nbsp;<br />
        4. 기타 e-CLUB의 업무에 방해되는 행위를 하여서는 안됩니다.<br />
        5. 회원은 서비스를 이용하여 얻은 정보를 e-CLUB의 사전 승낙 없이 복사, 복제, 변경, 번역, 출판&middot;방송 기타의 방법으로 사용하거나 이를 타인에게 제공할 수 없습니다.<br />
        6. 서비스의 이용권한, 기타 이용 계약상 지위를 타인에게 양도, 증여할 수 없으며, 이를 담보로 제공할 수 없습니다.<br />
        <br />
        제 16 조 (게시물 또는 내용물의 삭제)<br />
        <br />
        e-CLUB은 서비스의 게시물 또는 내용물이 아래의 규정에 위반되거나 소정의 게시기간을 초과하는 경우 사전 통지나 동의 없이 이를 삭제할 수 있습니다.<br />
        <br />
        1. 타인을 비방하거나 중상모략으로 개인 및 단체의 명예를 손상시키는 내용인 경우&nbsp;<br />
        2. 공공질서 및 미풍양속에 위반되는 내용인 경우&nbsp;<br />
        3. 범죄적 행위에 부합된다고 인정되는 내용인 경우&nbsp;<br />
        4. 타인의 저작권 등 기타의 권리를 침해하는 내용인 경우&nbsp;<br />
        5. 기타 관계 법령이나 회사에서 정한 규정에 위배되는 내용인 경우<br />
        6. 회원은 본 게시물 등록시 10점 댓글 등록이 1점이 부과 되며 활동성이 높은 회원은 등급이 상향 조정 된다.<br />
        <br />
        제 17 조 (게시물에 대한 권리&middot;의무)<br />
        <br />
        게시물에 대한 저작권을 포함한 모든 권리 및 책임은 이를 게시한 회원에게 있습니다.<br />
        <br />
        제 18 조 (손해배상)<br />
        <br />
        e-CLUB이 무료로 제공되는 서비스와 관련하여 회원에게 어떠한 손해가 발생하더라도 동 손해가 운영자의 중대한 과실에 의한 경우를 제외하고 이에 대하여 책임을 부담하지 아니합니다.<br />
        <br />
        제 20 조 (면책&middot;배상)<br />
        <br />
        1. e-CLUB은 회원이 서비스에 게재한 정보, 자료, 사실의 정확성, 신뢰성 등 그 내용에 관하여는 어떠한 책임을 부담하지 아니하고, 회원은 자기의 책임아래 서비스를 이용하며, 서비스를 이용하여 게시 또는 전송한 자료 등에 관하여 손해가 발생하거나 자료의 취사선택, 기타 서비스 이용과 관련하여 어떠한 불이익이 발생하더라도 이에 대한 모든 책임은 회원에게 있습니다.<br />
        <br />
        2. 회원 아이디(ID)와 비밀번호의 관리 및 이용상의 부주의로 인하여 발생되는 손해 또는 제3자에 의한 부정사용 등에 대한 책임은 모두 회원에게 있습니다.<br />
        <br />
        제 21 조 (분쟁의 해결)<br />
        <br />
        1. e-CLUB의 회원은 서비스와 관련하여 발생한 분쟁을 원만하게 해결하기 위하여 필요한 모든 노력을 하여야 합니다.<br />
        2. 위 규정에도 불구하고, 동 분쟁으로 인하여 소송이 제기될 경우 동 소송은 e-CLUB의 본사소재지를 관할하는 법원의 관할로 합니다.&nbsp;<br />

        <table align="center" border="0" cellpadding="0" cellspacing="0" style="width:800px;height:100px;background-color:#BDBDBD;margin-top:20px">
          <tbody>
          <tr>
            <td style="text-align: center;">부칙<br />
              <br />
              제 1 조 (시행일) 이 약관은 2009년 1 월 26 일부터 시행합니다.</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="btn-box"><a href="javascript:;" class="btn-submit dg-pop-close" @click="closePopup">확인</a></div>
    </div>
  </div>

</template>

<script>
export default {
  name: "Agree3Popup",
  data() {
    return {
    }
  },
  methods:{
    closePopup(){
      this.$emit('click:bgDimClose',false)
    }
  }
}
</script>

<style scoped>

</style>