import 'babel-polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import vueMoment from 'vue-moment'
import moment from 'moment'
import VueNumerals from 'vue-numerals'
import store from './store'
import VueGtag from "vue-gtag"
import axios from "axios";
import VueCookies from "vue-cookies"

import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'
import VueDaumPostcode from "vue-daum-postcode"

// import ElementUI from 'element-ui';
// import { ElementTiptapPlugin } from 'element-tiptap';
// import 'element-ui/lib/theme-chalk/index.css';
// import 'element-tiptap/lib/index.css';
// Vue.use(ElementUI);
// Vue.use(ElementTiptapPlugin, { lang: "ko" });

moment.locale('ko', {
  weekdays: ["일요일","월요일","화요일","수요일","목요일","금요일","토요일"],
  weekdaysShort: ["일","월","화","수","목","금","토"],
});

Vue.use(vueMoment, {moment})

Vue.use(VueNumerals)
Vue.use(VueCookies)
Vue.$cookies.config("1d")

Vue.use(Viewer)
Vue.use(VueDaumPostcode, {
  name: "daum-post"
})

axios.get(`${process.env.VUE_APP_BASE_API}/setting`).then(response => {
      // console.log(response)
      Vue.use(VueGtag, {
        config: {id: response.data.ext.analytics.id}
      }, router);
    }
);


Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  vueMoment,
  store,
  render: h => h(App)
}).$mount('#app')
