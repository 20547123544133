import Vue from 'vue'
import Vuex from 'vuex'

import user from './modules/user'
import error from './modules/error' 

Vue.use(Vuex)
const getters = {
  token : state => state.user.token,
  params: state => state.params,
  menu: state => state.currentMenu,
  config: state => state.config,
  counter: state => state.counter
}

export default new Vuex.Store({
  modules: {
    user,
    error
  },
  getters,
  state:{
    params: null,
    currentMenu: null,
    config: null,
    counter: null,
    menuInfoData: null,
    boardInfoData: null,
    tapOpen:false,
    
  },
  mutations: {
    setParams(state, payload) {
      state.params = payload
    },
    setMenu(state, payload) {
      state.currentMenu = payload
    },
    setConfig(state, payload) {
      state.config = payload
    },
    setCounter(state, payload) {
      state.counter = payload
    },
    setMenuInfoData(state, payload) {
      state.menuInfoData = payload
    },
    setBoardInfoData(state, payload) { 
        state.boardInfoData = payload
    },
    clearParams(state) {
      state.params = null
    },
    setTapOpen(state){
      state.tapOpen = !state.tapOpen
    }
  }
})
